import React, { useContext } from 'react';
import logo from '../assets/images/logo.png';
import { MobileContext } from './Layout';
import { DesktopMenu, MobileMenu } from './Menu';
import PatientenLogin from './PatientenLogin';

export default function Header() {
  const isMobile = useContext(MobileContext);

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    if(urlParams.get('open') === 'patientenlogin') {
        window.location.href = 'https://www.mein-leben-mit.info/mywayapp';
        return true;
    }

  if (isMobile) {
    return (
        <header data-header>
            <div>
                <a href="/">
                    <div className="logo">
                        <img width={62} height={60} src={logo} alt=""/>
                    </div>
                </a>
            </div>
            <MobileMenu/>
        </header>
    );
  } else {
      return (
          <header data-header>
              <div>
                  <div className="header-row">
                      <a href="/selbsttest">
                          Selbsttest
                      </a>
                      <a href="/aerztefinder">
                          Ärztefinder
                      </a>
                      <PatientenLogin/>
                  </div>
                  <div className="main-container">
                      <a href="/">
                          <div className="logo">
                              <img src={logo} alt=""/>
                          </div>
                      </a>
                      <DesktopMenu/>
                  </div>
              </div>
      </header>
    );
  }
}
