import { Link } from 'gatsby';
import React, { useContext, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { MobileContext } from './Layout';
import ResponsiveImage from './ResponsiveImage';

export default function QuicklinkMenu() {
  const isMobile = useContext(MobileContext);

  const [showElement, setShowElement] = React.useState(true);
  useEffect(() => {
    setTimeout(function () {
      setShowElement(false);
    }, 2500);
  }, []);

  if (!isMobile) {
    return (
      <>
        <Row
          className="quicklink-desktop-menu"
          style={{ zIndex: '100', right: showElement ? '-20px' : '-200px' }}>
          <Col>
            <a href="/selbsttest">
              <Row
                className="quicklink-box text-center"
                style={{
                  borderRight: '2px solid #E7E1F4',
                  borderBottom: '2px solid #E7E1F4',
                }}>
                <Col sm={3} style={{ alignSelf: 'center' }}>
                  <ResponsiveImage
                    loading="eager"
                    className="quicklink-icon p-0 col"
                    imgName="quicklink_question_mark"
                  />
                </Col>
                <Col sm={9} style={{ alignSelf: 'center' }}>
                  <p style={{ textAlign: 'start' }}>Selbsttest</p>
                </Col>
              </Row>
            </a>
            <Link to="/neurodermitis-berater-kontaktieren">
              <Row
                className="quicklink-box text-center"
                style={{
                  borderRight: '2px solid #E7E1F4',
                  borderBottom: '2px solid #E7E1F4',
                }}>
                <Col sm={3} style={{ alignSelf: 'center' }}>
                  <ResponsiveImage
                    loading="eager"
                    className="quicklink-icon p-0"
                    imgName="quicklink_service_contact"
                  />
                </Col>
                <Col sm={9} style={{ alignSelf: 'center' }}>
                  <p style={{ textAlign: 'start' }}>Service-Kontakt</p>
                </Col>
              </Row>
            </Link>
            <Link to="/aerztefinder">
              <Row
                className="quicklink-box text-center"
                style={{
                  borderBottom: '2px solid #E7E1F4',
                }}>
                <Col sm={3} style={{ alignSelf: 'center' }}>
                  <ResponsiveImage
                    loading="eager"
                    className="quicklink-icon p-0 col"
                    imgName="quicklink_expert_finder"
                  />
                </Col>
                <Col sm={9} style={{ alignSelf: 'center' }}>
                  <p style={{ textAlign: 'start' }}>Ärztefinder</p>
                </Col>
              </Row>
            </Link>
          </Col>
        </Row>
      </>
    );
  } else {
    return (
      <Container
        className="quicklink-mobile-menu"
        style={{ backgroundColor: 'white', zIndex: '100' }}>
        <Row>
          <Col
            className="quicklink-box text-center"
            xs={4}
            style={{ borderRight: '2px solid #E7E1F4' }}>
            <a href="/selbsttest">
              <ResponsiveImage
                loading="eager"
                className="quicklink-icon"
                imgName="quicklink_question_mark"
              />
              <p>Selbsttest</p>
            </a>
          </Col>
          <Col
            className="quicklink-box text-center"
            xs={4}
            style={{ borderRight: '2px solid #E7E1F4' }}>
            <Link to="/neurodermitis-berater-kontaktieren">
              <ResponsiveImage
                loading="eager"
                className="quicklink-icon"
                imgName="quicklink_service_contact"
              />
              <p>Service-Kontakt</p>
            </Link>
          </Col>
          <Col className="quicklink-box text-center" xs={4}>
            <Link to="/aerztefinder">
              <ResponsiveImage
                loading="eager"
                className="quicklink-icon"
                imgName="quicklink_expert_finder"
              />
              <p>Ärztefinder</p>
            </Link>
          </Col>
        </Row>
      </Container>
    );
  }
}
